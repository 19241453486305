import React from "react";
import logo from "../images/img1.png";

const Section = () => {
  return (
    <div>
   
      <section className="bg-main bg-color hero-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 col-md-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
              <h1 className="text-capitalize fw-bolder text-white">
                {" "}
                We Provide DevOps Solutions & Services
              </h1>
              <p className="mt-3 mb-5 para-width text-light-grey">
                Welcome to Kubicl Technologies Pvt. Ltd. , your trusted partner
                for DevOps solutions. Explore our website to discover how we
                streamline software development and IT operations, enabling your
                business to deliver high-quality applications faster.
              </p>
              <div className="text-center w-100 text-md-start">
                <button className="text-capitalize btn btn-primary px-5 py-2">Contact Us</button>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6">
              <div className="text-center text-lg-end">
                <img src={logo} alt="Img" className="hero-image-section" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section;

import Navbar from "./components/Navbar";
import Section from "./components/Section";
import "./App.css";
// import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Section />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
